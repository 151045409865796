<template>
  <section class="orders-header">
    <h3 class="titlePage titlePage_textLeft">Apply Sets</h3>


    <div class="row"
         v-for="(item, index) in sets"
         :ke="index"
         v-if="cheAvailablesSet(item)"
    >
      <div class="col-12">
        <p class="text">{{index}}</p>
      </div>
      <div class="col-md-4 col-sm-6"
           v-for="set in item"
           :key="set.id"
      >
        <div class="set__item" @click="selectSet(set)">
          <h3 class="text text_green" :title="set.name">{{set.name}}</h3>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    name: "ApplySets",

    data() {
      return {}
    },

    computed: {
      ...mapState('applySet', ['sets']),
    },

    methods: {
      selectSet(item) {
        this.$store.commit('applySet/updatePackages', item.packages);
        this.$store.commit('applySet/updateSetToColor', item.item_id);
        this.$store.commit('applySet/updateSetId', item.id);
        this.$store.commit('applySet/updateWidthDrop', {width: '', drop: ''});

        this.$parent.sets = false;
        this.$parent.package = true;
      },

      cheAvailablesSet(item){
        if(item.length > 0){
          return true;
        }
        else return false;
      }
    },

    created() {
      this.$store.dispatch('applySet/getSets');
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .set__item {
    cursor: pointer;
    margin: 0;
    margin-bottom: 20px;
    flex: none;
  }
</style>
